import React, { useRef, useState } from 'react'
import { graphql } from 'gatsby'

import { Seo } from '../../components/base'
import { LayoutBackground } from '../../components/shared'
import { Layout, Search, RecipeGrid } from '../../components'
import CollectionHero, { Item } from '../../components/CollectionHero'
import { getLocale } from '../../utils/localeUtils'

const RecipePage = ({ data, location, pageContext }) => {
  const [filterIsTriggered, setFilterIsTriggered] = useState(false)
  const {
    overviewHeadline,
    postTeaserLeft,
    postTeaserRight,
    _rawNoSearchResultsText,
    seo
  } = data.sanityRecipePage.nodes[0]
  const languages = { pageContext }
  const recipes = data.sanityRecipes.nodes

  const layoutBackgroundHeaderComponent = (
    <h1 className="mb-14 md:mb-24">{overviewHeadline}</h1>
  )
  const searchResultRef = useRef()
  const locale = getLocale()

  return (
    <>
      <Layout location={location} title={''} translations={languages}>
        <Seo
          title={seo?.metaTitle}
          description={seo?.metaDescription}
          image={seo?.ogImage?.asset?.url}
        />
        <CollectionHero>
          <Item
            contentItem={postTeaserLeft}
            whiteFont
            headlineTextShadow
            leftItem
            subTitleUp
          />
          <Item contentItem={postTeaserRight} rightItem />
        </CollectionHero>
        <LayoutBackground headerComponent={layoutBackgroundHeaderComponent}>
          <div
            ref={searchResultRef}
            className="flex flex-wrap justify-between relative">
            <Search
              showToTopButton
              noInitialRender
              index="recipe"
              initialItems={recipes}
              count={data.sanityRecipes.totalCount}
              searchListHeight={searchResultRef?.current?.offsetHeight}
              locale={locale}
              setFilterIsTriggered={setFilterIsTriggered}>
              <RecipeGrid
                items={recipes}
                noSearchResultsText={_rawNoSearchResultsText}
              />
            </Search>
          </div>
        </LayoutBackground>
      </Layout>
    </>
  )
}

export default RecipePage

export const something = graphql`
  query ($language: String! = "de_de") {
    sanityRecipePage: allSanityRecipePage(
      filter: {
        i18n_lang: { eq: $language }
        isNotAvailableInCountry: { ne: true }
      }
    ) {
      nodes {
        title
        subtitle
        _rawNoSearchResultsText
        postTeaserLeft {
          ...TeaserContentQuery
        }
        postTeaserRight {
          ...TeaserContentQuery
        }
        overviewHeadline
        seo {
          ...SeoQuery
        }
      }
    }
    sanityRecipes: allSanityRecipe(
      limit: 12
      sort: { fields: [_updatedAt], order: ASC }
      filter: {
        i18n_lang: { eq: $language }
        isNotAvailableInCountry: { ne: true }
      }
    ) {
      totalCount
      nodes {
        title
        preparationTime
        vegan
        vegetarian
        lactosefree
        glutenfree
        basePath
        slug: slug {
          current
        }
        previewImage {
          ...ImageObjectQuery
        }
      }
    }
  }
`
